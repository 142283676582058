
















































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'

import { Component, Mixins } from 'vue-property-decorator'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'

@Component({
  components: {
    CurrentModule,
    CenteredColumnLayout,
    PageTitle,
    ExtendedHelpMessage
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ChatRules extends Mixins(UseFields, InputSetups, TariffsTagsHelper, TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.ChatTopCommandHandlerModule)
  }
}
